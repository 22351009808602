import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { createSelector } from "reselect";
import { Button, Modal } from "@freecodecamp/ui";

import { closeModal, resetChallenge } from "../redux/actions";
import { isResetModalOpenSelector } from "../redux/selectors";
import callGA from "../../../analytics/call-ga";

interface ResetModalProps {
  close: () => void;
  isOpen: boolean;
  reset: () => void;
}

const mapStateToProps = createSelector(
  isResetModalOpenSelector,
  (isOpen: boolean) => ({
    isOpen,
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      close: () => closeModal("reset"),
      reset: () => resetChallenge(),
    },
    dispatch
  );

function withActions(...fns: Array<() => void>) {
  return () => fns.forEach((fn) => fn());
}

function ResetModal({ reset, close, isOpen }: ResetModalProps): JSX.Element {
  const { t } = useTranslation();
  if (isOpen) {
    callGA({ event: "pageview", pagePath: "/reset-modal" });
  }
  return (
    <Modal onClose={close} open={isOpen} variant="primary">
      <Modal.Header showCloseButton={true} closeButtonClassNames="close">
        {t("learn.reset")}
      </Modal.Header>
      <Modal.Body alignment="center">
        <p>{t("learn.reset-warn")}</p>
        <p>
          <em>{t("learn.reset-warn-2")}</em>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          style={{
            width: "100%",
            background: "#BB2124",
            display: "flex",
            alignItems: "center",
            justifyContent: "center", // Center should be a string
          }}
          block={true}
          variant="primary"
          onClick={withActions(reset, close)}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_254_1596)">
              <path
                d="M12.375 19.25C14.0067 19.25 15.6018 18.7661 16.9585 17.8596C18.3152 16.9531 19.3726 15.6646 19.997 14.1571C20.6214 12.6497 20.7848 10.9909 20.4665 9.39051C20.1482 7.79017 19.3624 6.32016 18.2086 5.16637C17.0548 4.01259 15.5848 3.22685 13.9845 2.90853C12.3842 2.5902 10.7254 2.75357 9.21786 3.378C7.71037 4.00242 6.4219 5.05984 5.51538 6.41655C4.60885 7.77326 4.125 9.36831 4.125 11V15.2625L1.65 12.7875L0.6875 13.75L4.8125 17.875L8.9375 13.75L7.975 12.7875L5.5 15.2625V11C5.5 9.64026 5.90321 8.31105 6.65865 7.18046C7.41408 6.04987 8.48781 5.16868 9.74405 4.64833C11.0003 4.12798 12.3826 3.99183 13.7162 4.2571C15.0499 4.52238 16.2749 5.17716 17.2364 6.13864C18.1978 7.10013 18.8526 8.32514 19.1179 9.65876C19.3832 10.9924 19.247 12.3747 18.7267 13.631C18.2063 14.8872 17.3251 15.9609 16.1945 16.7164C15.064 17.4718 13.7347 17.875 12.375 17.875V19.25Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_254_1596">
                <rect width="22" height="22" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {t("buttons.reset-lesson")}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

ResetModal.displayName = "ResetModal";

export default connect(mapStateToProps, mapDispatchToProps)(ResetModal);
